<template>
  <div class="task-item" @click="handleChoose(info)" v-if="info">
    <img class="choose" v-if="info.choose" src="@/assets/images/img-choose.png">
    <img class="avatar" :src="info.logo || require('@/assets/images/avatar-default.png')">
    <div class="tag">{{info.taskTypeName}}</div>
    <div class="pname">{{info.projectName}}</div>
    <div class="flex items-center">
      <img class="tbj" v-if="(info.taskTag&1)==1" src="@/assets/images/tui.png">
      <img class="tbj" v-if="(info.taskTag&1)==2" src="@/assets/images/ding.png">
      <img class="tbj" v-if="(info.taskTag&1)==4" src="@/assets/images/bao.png">
      <div class="desc">{{info.taskTitle}}</div>
    </div>
    <div class="num">
      <span>{{info.completeNum}}人已赚</span>
      <span>剩余{{info.leftNum}}</span>
    </div>
    <div class="price">+{{info.price}}{{info.priceUnit}}</div>
  </div>
  <!-- <div class="task-item" >
    <img class="avatar" src="@/assets/images/avatar-default.png">
    <div class="tag">下载</div>
    <div class="pname">测试111</div>
    <div class="flex items-center">
      <img class="tbj" src="@/assets/images/tui.png">
      <div class="desc">测试22222</div>
    </div>
    <div class="num">
      <span>1000人已赚</span>
      <span>剩余5000</span>
    </div>
    <div class="price">+1.00元</div>
  </div> -->
</template>

<script>
export default {
  data() {
    return {

    }
  },
  props: {
    info: {
      type: Object,
      default: () => null
    }
  },
  methods: {
    handleChoose(info) {
      this.$emit('choose', info)
    }
  }
}
</script>

<style lang="scss" scoped>
.task-item {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #fff;
  border-radius: 8px;
  overflow: hidden;
  padding: 8px;
  width: 146px;
  cursor: pointer;
  .avatar {
    width: 32px;
    height: 32px;
    border-radius: 16px;
  }
  .tag {
    height: 16px;
    line-height: 16px;
    padding: 0 6px;
    border-radius: 8px;
    border: 1px solid #B0B3BF;
    color: #B0B3BF;
    margin-top: 8px;
    font-size: 10px;
  }
  .pname {
    font-size: 12px;
    color: #666666;
    margin: 8px 0;
  }
  .tbj {
    width: 16px;
    height: 16px;
    margin-right: 4px;
  }
  .desc {
    font-size: 14px;
    max-width: 90px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    width: 100%;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  .num {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    color: #B0B3BF;
    margin-top: 8px;
    span {
      &:nth-child(1) {
        margin-right: 10px;
      }
    }
  }
  .price {
    margin-top: 8px;
    color: var(--theme-color-red);
    font-size: 16px;
    font-weight: 500;
  }
  .choose {
    position: absolute;
    top: 0;
    right: 0;
    width: 32px;
    height: 32px;
  }
}
</style>