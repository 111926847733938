<template>
  <div>
    <div class="task" v-if="info">
      <LoadImage class="avatar" :src="info.logo" :default="require('@/assets/images/avatar-default.png')"/>
      <div class="flex between-center flex-1">
        <div>
          <div class="pname">{{ info.projectName }}</div>
          <div class="ptitle">{{ info.taskTitle || info.title }}</div>
        </div>
        <div>
          <div class="price">
            +{{ info.price }}{{ info.priceUnit }}
          </div>
          <div class="num">{{ info.completeNum }}人已赚</div>
        </div>
      </div>
    </div>
    <!-- 选择任务 -->
    <div class="btn-choose" v-else>
      <img src="@/assets/images/add-plus-theme.png">
      <span>选择任务</span>
    </div>
  </div>
</template>

<script>
import LoadImage from "@/components/LoadImage";
export default {
  components: {
    LoadImage
  },
  data() {
    return {}
  },
  props: {
    info: {
      type: Object,
      default: () => null
    }
  }
}
</script>

<style lang="scss" scoped>
.task {
  display: flex;
  align-items: center;
  border: 1px solid var(--theme-color);
  padding: 8px 16px;
  border-radius: 8px;
  .avatar {
    width: 40px;
    height: 40px;
    border-radius: 20px;
    margin-right: 12px;
    overflow: hidden;
  }
  .pname {
    color: #65666f;
    font-size: 12px;
  }
  .ptitle {
    font-size: 15px;
    font-weight: bold;
    margin-top: 4px;
  }
  .price {
    font-size: 14px;
    font-weight: bold;
    color: var(--theme-color-red);
  }
  .num {
    font-size: 12px;
    color: #b0b3bf;
    margin-top: 4px;
  }
}
.btn-choose {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 56px;
  font-size: 14px;
  color: var(--theme-color);
  border: 1px dashed #B0B3BF;
  border-radius: 8px;
  cursor: pointer;
  img {
    width: 20px;
    height: 20px;
    margin-right: 8px;
  }
}
</style>