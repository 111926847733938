<template>
  <div class="pro-section panel">
    <PanelTitle title="其它任务推广功能"/>
    <div class="row" v-if="spreadSections && spreadSections.length > 0">
      <template v-for="(item, index) in spreadSections[0].actions">
        <div v-if="index != spreadExcludeId" :key="index" @click="spreadAction(item, index)">
          <img :src="getPromoteIcon(item)">
          <div>{{item.text}}</div>
        </div>
      </template>
      
    </div>
    <!-- 修改/刷新弹窗 -->
    <RefreshAndModify ref="refreshModifyRef"></RefreshAndModify>
  </div>
</template>

<script>
import PanelTitle from '@/components/PanelTitle'
import RefreshAndModify from '@/components/RefreshAndModify'
import { merchantManageApi } from '@/api/merchant.js'
import { mapState } from 'vuex'
export default {
  name: "SpreadSection",
  components: {
    PanelTitle,
    RefreshAndModify
  },
  data() {
    return {
      spreadSections: []
    }
  },
  props: {
    excludeIndex: {
      type: [Number, String],
      default: ''
    }
  },
  computed: {
    ...mapState(['spreadExcludeId'])
  },
  created() {
    this.getMerchatManage()
  },
  methods: {
    getMerchatManage() {
      merchantManageApi().then(res => {
        console.log('商家管理数据', res)
        this.spreadSections = res.data.spreadSections
      }).catch(err => {
        this.$tips({message: err, type: 'error'})
      })
    },
    spreadAction(info, index) {
      console.log(info)
      if(info.url.includes('index=43')) {
        this.$refs.refreshModifyRef.open()
      }
      if(info.url.includes('index=44')) {
        this.$router.replace('/auto?type=' + 0)
      }
      if(info.url.includes('index=71')) {
        this.$router.replace('/headlineAppoint')
      }
      if(info.url.includes('index=45')) {
        this.$router.replace('/seckill')
      }
      if(info.url.includes('index=57')) {
        this.$router.replace('/auction?type=0')
      }
      if(info.url.includes('index=64')) {
        this.$router.replace('/quickReview')
      }
      if(info.url.includes('index=1016')) {
        const extInfo = info.extInfo
        this.$router.replace(`/auction?type=${extInfo.type}&title=${extInfo.title}`)
      }
      if(info.url.includes('index=1015')) {
        this.$router.replace('/searchSeat')
      }
      this.$store.commit('SET_SPREAD_EXCLUDE_ID', index)
    },
    getPromoteIcon(item) {
      const url = item.url
      const extInfo = item.extInfo
      if(url.includes('index=71')) return require('@/assets/images/icon-ttyy.png')
      if(url.includes('index=45')) return require('@/assets/images/icon-mstj.png')
      if(url.includes('index=57')) return require('@/assets/images/icon-syjj.png')
      if(url.includes('index=64')) return require('@/assets/images/icon-mszq.png')
      if(url.includes('index=44')) return require('@/assets/images/icon-zdsx.png')
      if(url.includes('index=43')) return require('@/assets/images/icon-xgsx.png')
      if(url.includes('index=1016')) {
        if(extInfo.type == 1) return require('@/assets/images/icon-dtjj.png')
        if(extInfo.type == 2) return require('@/assets/images/icon-tctj.png')
      }
      if(url.includes('index=1015')) return require('@/assets/images/icon-rmss.png')
    }
  }
}
</script>

<style lang="scss" scoped>
.row {
  display: flex;
  flex-wrap: wrap;
  font-size: 14px;
  text-align: center;
  &>div {
    width: 56px;
    margin: 16px 30px 0 0;
    cursor: pointer;
    &:nth-child(3n) {
      margin-right: 0;
    }
  }
  img {
    width: 48px;
    height: 48px;
    border-radius: 16px;
    margin-bottom: 8px;
  }
}
</style>