<template>
  <div class="content">
    <div class="content-left">
      <slot name="left"></slot>
    </div>
    <div class="content-right">
      <slot name="right"></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "ContentLayout",
  data() {
    return {}
  }
}
</script>

<style lang="scss" scoped>
.content {
  display: flex;
  // margin-top: 24px;
}
.content-left {
  flex: 1;
}
.content-right {
  width: 282px;
  margin-left: 24px;
}
</style>