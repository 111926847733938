<template>
  <Popup ref="popupRef" :title="title" width="700px" :confirm-text="confirmText" :cancel-text="cancelText" @confirm="confirm" @close="resetData">
    <div class="container">
      <div class="total-task" v-if="taskList.length > 0">
        <div class="total-item" v-for="(item, index) in taskList" :key="index">
          <TaskItem :info="item" @choose="handleChoose"></TaskItem>
        </div>
        <!-- <div class="total-item" v-for="(item, index) in 30" :key="index">
          <TaskItem></TaskItem>
        </div> -->
      </div>
      <Empty v-else class="mt16 mb16"></Empty>
      <div v-if="taskList.length > 0" class="btn-more" @click="loadmore">加载更多</div>
    </div>
  </Popup>
</template>

<script>
import Popup from '@/components/Popup'
import TaskItem from '@/components/TaskItem'
import Empty from '@/components/Empty'
import { pubTasksApi } from '@/api/task.js'
export default {
  components: {
    Popup,
    TaskItem,
    Empty
  },
  data() {
    return {
      // taskList: [],
      currentTask: null,
      curPage: 1,
    }
  },
  props: {
    list: {
      type: Array,
      default: () => []
    },
    title: {
      type: String,
      default: '你的全部任务'
    },
    confirmText: {
      type: String,
      default: '确认选择'
    },
    cancelText: {
      type: String,
      default: '取消'
    }
  },
  computed: {
    taskList() {
      if(!this.list) return []
      return this.list.map(item => {
        this.$set(item, 'choose', false)
        return item
      })
    }
  },
  // watch: {
  //   list(newList) {
  //     newList.forEach(item => {
  //       this.$set(item, 'choose', false)
  //     })
  //     this.taskList = newList
  //   }
  // },
  created() {
    // this.getTaskList()
  },
  methods: {
    open() {
      this.$refs.popupRef.open()
    },
    close() {
      this.$refs.popupRef.close()
      this.$emit('close')
    },
    resetData() {
      this.currentTask = null
      this.curPage = 1
      this.taskList.forEach(item => {
        item.choose = false
      })
    },
    // getTaskList() {
    //   pubTasksApi({
    //     'startPage': this.curPage,
		// 		'type': 0
    //   }).then(res => {
    //     console.log('全部-任务列表', res)
    //     this.taskList = res.data.taskListItems
    //     this.taskList.forEach(item => {
    //       this.$set(item, 'choose', false)
    //     })
    //   }).catch(err => {
    //     this.$tips({message: err, type: 'error'})
    //   })
    // },
    handleChoose(info) {
      this.taskList.forEach(item => {
        if(info.taskId == item.taskId) {
          item.choose = true
        } else {
          item.choose = false
        }
      })
      this.currentTask = info
    },
    loadmore() {
      this.$emit('loadmore', {page: this.curPage + 1})
    },
    confirm() {
      if(!this.currentTask) return this.$tips({message: '请选择任务', type: 'warning'})
      this.$emit('confirm', {detail: this.currentTask})
      this.close()
    }
  }
}
</script>

<style lang="scss" scoped>
::-webkit-scrollbar {
	width: 8px;
	height: 6px;
}

::-webkit-scrollbar-thumb {
	background-color: #D9D9D9;
	border-radius: 32px;
}

::-webkit-scrollbar-track {
	background-color: #F6F6F6;
	border-radius: 32px;
}
.container {
  max-height: 600px;
  overflow-y: auto;
  background-color: #f6f6f6;
}
.total-task {
  display: flex;
  flex-wrap: wrap;
  border-radius: 16px;
  padding: 16px;
}
.total-item {
  margin: 0 12px 12px 0;
  &:nth-child(4n) {
    margin-right: 0;
  }
}
.btn-more {
  text-align: center;
  cursor: pointer;
  padding-bottom: 20px;
}
</style>